import { createMuiTheme } from "@material-ui/core/styles";

export default createMuiTheme({
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
  overrides: {
    MuiIconButton: {
      root: {
        color: "#fff",
        opacity: 0.7,
        transition: "all cubic-bezier(0.4, 0, 0.2, 1) 300ms",

        "&:hover": {
          opacity: 1,
          backgroundColor: "unset",
        },
      },
    },
    MuiLink: {
      underlineHover: {
        borderBottom: "1px solid transparent",
        transition: "all cubic-bezier(0.4, 0, 0.2, 1) 300ms",

        "&:hover": {
          borderColor: "#4285f4",
          textDecoration: "none",
        },
      },
    },
  },
  palette: {
    type: "dark",
    primary: {
      main: "#2997ff",
    },
    success: {
      main: "#4caf50",
      dark: "#2e7d32",
    },
    background: {
      paper: "#212121",
      default: "#131313",
    },
  },
  props: {
    MuiAppBar: { color: "default" },
    MuiContainer: { maxWidth: "lg" },
    MuiIconButton: {
      disableRipple: true,
    },
    MuiLink: {
      target: "_blank",
      rel: "noreferrer",
    },
  },
  transitions: {
  },
  typography: {
    fontWeightRegular: 300,
    h1: { fontSize: "4rem" },
    h2: { fontSize: "2rem" },
    h3: { fontSize: "1.5rem" },
    h4: { fontSize: "1.3rem" },
    h5: { fontSize: "1rem" },
    h6: { fontSize: "0.8rem" },
  },
  zIndex: {
    appBar: 1350,
  },
});
